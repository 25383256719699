import React from "react"
import styled from "styled-components"
import {
    TopHeaderLogoSVG,
    HeaderLogoSVG,
    FaceBookLinkSVG,
    MemberButtonIcon,
} from "../../Images/svg"

const IndexHeroButtonWrapper = styled.a`
    width: 168px;
    height: 26px;
    border-bottom: 1px solid white;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    letter-spacing: 1px;
    color: white;
    text-decoration: none;
    transition: 0.4s all;
    &:hover {
        width: 140px;
    }
`

export const IndexHeroButton = () => {
    return (
        <IndexHeroButtonWrapper href="/vision">
            OUR VISION
        </IndexHeroButtonWrapper>
    )
}

const LogoButtonOuter = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const LogoButton = props => {
    return (
        <LogoButtonOuter href="/">
            {props.color === "white" && <TopHeaderLogoSVG />}
            {props.color === "black" && <HeaderLogoSVG />}
        </LogoButtonOuter>
    )
}

const WhiteLinkButtonOuter = styled.a`
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    color: white;
    text-decoration: none;
    margin: 7px 0px 7px 0px;
    padding: 0px 8px 0px 8px;
    transition: 0.4s all;
    &:hover {
        color: rgba(255, 255, 255, 0.7);
    }
`

const BlackLinkButtonOuter = styled.a`
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    color: black;
    text-decoration: none;
    padding: 0px 8px 0px 8px;
    transition: 0.4s all;
    &:hover {
        color: #696969;
    }
`

export const LinkButton = props => {
    if (props.color === "white") {
        return (
            <WhiteLinkButtonOuter href={props.linkto}>
                {props.label}
            </WhiteLinkButtonOuter>
        )
    } else {
        return (
            <BlackLinkButtonOuter href={props.linkto}>
                {props.label}
            </BlackLinkButtonOuter>
        )
    }
}

const ViewAllButtonOuter = styled.a`
    display: flex;
    align-items: center;
    width: 115px;
    height: 46px;
    border-bottom: 1px solid white;
    text-decoration: none;
    margin: 40px 0px 0px 0px;
    transition: 0.4s all;
    color: white;
    &:hover {
        width: 105px;
    }
`

const ViewAllButtonInner = styled.p`
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
`

export const ViewAllButton = props => {
    return (
        <ViewAllButtonOuter href={props.linkto}>
            <ViewAllButtonInner>VIEW ALL</ViewAllButtonInner>
        </ViewAllButtonOuter>
    )
}

const SiteTopButtonOuter = styled.a`
    display: flex;
    align-items: center;
    width: 115px;
    height: 46px;
    padding-bottom: 15px;
    border-bottom: 1px solid black;
    text-decoration: none;
    margin: 50px 0px 0px 0px;
    transition: 0.4s all;
    color: black;
    &:hover {
        width: 105px;
        cursor: pointer;
    }
`

const SiteTopButtonInner = styled.p`
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
`

export const SiteTopButton = props => {
    return (
        <SiteTopButtonOuter href={props.linkto}>
            <SiteTopButtonInner>SITE TOP</SiteTopButtonInner>
        </SiteTopButtonOuter>
    )
}

const GoToTopButtonWrapper = styled.a`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    bottom: 60px;
    width: 120px;
    height: 140px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    color: white;
    text-decoration: none;
    transition: 0.4s all;
    &:hover {
        letter-spacing: 1.5px;
    }
`

const GoToTopLine = styled.div`
    display: inline-block;
    width: 1px;
    height: 80px;
    margin-bottom: 7px;
    background: white;
`

export const GoToTopButton = () => {
    return (
        <GoToTopButtonWrapper href="#">
            <GoToTopLine />
            GO TO TOP
        </GoToTopButtonWrapper>
    )
}

const FaceBookLinkButtonOuter = styled.a`
    position: relative;
    bottom: 10px;
    padding: 10px;
    fill: white;
    transition: 0.4s all;
    &:hover {
        fill: rgba(255, 255, 255, 0.7);
    }
`

export const FaceBookLinkButton = () => {
    return (
        <FaceBookLinkButtonOuter href="https://www.facebook.com/QUANDOInc/">
            <FaceBookLinkSVG />
        </FaceBookLinkButtonOuter>
    )
}

const ActiveTabButton = styled.button`
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 50%;
    height: auto;
    border: none;
    border-radius: 0px;
    background: transparent;
    font-family: "Noto Sans JP", sans-serif;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 0.34em;
    &:before {
        content: "";
        position: absolute;
        display: inline-block;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 2px;
        background: black;
    }
    &:hover {
        transition: 0.4s color;
        color: #909090;
        background: none;
    }
`

const InactiveTabButton = styled.button`
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 50%;
    height: auto;
    border: none;
    border-radius: 0px;
    background: transparent;
    transition: 0.4s color;
    font-family: "Noto Sans JP", sans-serif;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 0.34em;
    &:before {
        content: "";
        position: absolute;
        display: inline-block;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 1px;
        background: #ccc;
    }
    &:hover {
        transition: 0.4s color;
        color: #909090;
        background: none;
    }
`

const JapaneseLabel = styled.div`
    margin-bottom: 9px;
`

const EnglishLabel = styled.div`
    margin-bottom: 12px;
    opacity: 0.6;
    font-size: 10px;
`

export const TabButton = props => {
    const onActive = props.tabIndex === props.activeTabIndex

    return onActive ? (
        <ActiveTabButton onClick={() => props.onClick(props.tabIndex)}>
            <JapaneseLabel>{props.jpLabel}</JapaneseLabel>
            <EnglishLabel>{props.enLabel}</EnglishLabel>
        </ActiveTabButton>
    ) : (
        <InactiveTabButton onClick={() => props.onClick(props.tabIndex)}>
            <JapaneseLabel>{props.jpLabel}</JapaneseLabel>
            <EnglishLabel>{props.enLabel}</EnglishLabel>
        </InactiveTabButton>
    )
}

const DoubleLabelButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
`

const DoubleLabelButtonOuter = styled.a`
    height: 120px;
    width: 58%;
    min-width: 900px;
    font-size: 22px;
    border: 1px solid #b2b2b2;
    background: none;
    position: relative;
    display: flex;
    align-items: center;
    fill: black;
    text-decoration: none;
    color: black;
    text-transform: uppercase;
    letter-spacing: 1px;
    &:before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        content: "";
        background-color: black;
        transform-origin: right top;
        transform: scale(0, 1);
        transition: transform 0.3s;
    }
    &:hover:before {
        transform-origin: left top;
        transform: scale(1, 1);
    }
    &:hover {
        color: #fff;
        fill: white;
        cursor: pointer;
    }
`

const DoubleLabelButtonInner = styled.div`
    display: flex;
    font-weight: 600;
    font-size: 22px;
    margin-left: 30px;
    align-items: center;
`

const DoubleLabelButtonExplanation = styled.p`
    padding-bottom: 15px;
    width: 58%;
    min-width: 900px;
    margin: 0 auto;
`

export const DoubleLabelButton = ({ jpLabel, enLabel, href }) => {
    return (
        <DoubleLabelButtonWrapper>
            <DoubleLabelButtonExplanation>
                {jpLabel}
            </DoubleLabelButtonExplanation>
            <DoubleLabelButtonOuter href={href}>
                <DoubleLabelButtonInner>{enLabel}</DoubleLabelButtonInner>
                <MemberButtonIcon />
            </DoubleLabelButtonOuter>
        </DoubleLabelButtonWrapper>
    )
}

const SNSIconButtonWrapper = styled.a`
    transition: 0.4s all;
    cursor: pointer;
`

export const SNSIconButton = props => {
    return (
        <SNSIconButtonWrapper
            onClick={() => {
                window.open(props.linkto, "_blank")
            }}
        >
            {props.children}
        </SNSIconButtonWrapper>
    )
}

const ReadMoreButtonWrapper = styled.a`
    display: inline-block;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    width: 120px;
    height: 28px;
    border-bottom: 1px solid black;
    color: black;
    cursor: pointer;
    text-decoration: none;
    transition: 0.4s all;
    &:hover {
        width: 100px;
    }
`

export const ReadMoreButton = ({ href }) => {
    return <ReadMoreButtonWrapper href={href}>Read More</ReadMoreButtonWrapper>
}
