import React from "react"
import styled from "styled-components"
import { HeaderLogoSVG, MemberButtonIcon, TopHeaderLogoSVG } from "../../Images/svg"

const MobileSiteTopButtonOuter = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 114px;
    height: 25px;
    padding-bottom: 7px;
    border-bottom: 1px solid black;
    text-decoration: none;
    margin: 50px 0px 0px 0px;
    transition: 0.4s all;
    color: black;
    &:hover {
        width: 105px;
        cursor: pointer;
    }
`

const MobileSiteTopButtonInner = styled.p`
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
`

export const MobileSiteTopButton = props => {
    return (
        <MobileSiteTopButtonOuter href={props.linkto}>
            <MobileSiteTopButtonInner>SITE TOP</MobileSiteTopButtonInner>
        </MobileSiteTopButtonOuter>
    )
}

const MobileActiveTabButton = styled.button`
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 50%;
    height: auto;
    border: none;
    border-radius: 0px;
    background: transparent;
    font-family: "Noto Sans JP", sans-serif;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    &:before {
        content: "";
        position: absolute;
        display: inline-block;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 2px;
        background: black;
    }
    &:hover {
        transition: 0.4s color;
        color: #909090;
        background: none;
    }
`

const MobileInactiveButton = styled.button`
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 50%;
    height: auto;
    border: none;
    border-radius: 0px;
    background: transparent;
    transition: 0.4s color;
    font-family: "Noto Sans JP", sans-serif;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    &:before {
        content: "";
        position: absolute;
        display: inline-block;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 1px;
        background: #ccc;
    }
    &:hover {
        transition: 0.4s color;
        color: #909090;
        background: none;
    }
`

const MobileJapaneseLabel = styled.div`
    margin-bottom: 9px;
`

const MobileEnglishLabel = styled.div`
    font-size: 6px;
    margin-bottom: 12px;
    opacity: 0.6;
`

export const MobileTabButton = props => {
    const onActive = props.tabIndex === props.activeTabIndex

    return onActive ? (
        <MobileActiveTabButton onClick={() => props.onClick(props.tabIndex)}>
            <MobileJapaneseLabel>{props.jpLabel}</MobileJapaneseLabel>
            <MobileEnglishLabel>{props.enLabel}</MobileEnglishLabel>
        </MobileActiveTabButton>
    ) : (
        <MobileInactiveButton onClick={() => props.onClick(props.tabIndex)}>
            <MobileJapaneseLabel>{props.jpLabel}</MobileJapaneseLabel>
            <MobileEnglishLabel>{props.enLabel}</MobileEnglishLabel>
        </MobileInactiveButton>
    )
}

const MobileMemberButtonOuter = styled.button`
    height: 65px;
    width: 80%;
    max-width: 500px;
    font-size: 22px;
    border: 1px solid #b2b2b2;
    background: none;
    position: relative;
    display: flex;
    align-items: center;
    fill: black;
    margin: 0 auto;
    &:before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        content: "";
        background-color: black;
        transform-origin: right top;
        transform: scale(0, 1);
        transition: transform 0.3s;
    }
    &:hover:before {
        transform-origin: left top;
        transform: scale(1, 1);
    }
    &:hover {
        color: #fff;
        fill: white;
        cursor: pointer;
    }
`

const MobileMemberButtonInner = styled.div`
    display: flex;
    font-weight: 600;
    font-size: 12px;
    margin-left: 30px;
    align-items: center;
`
const MobileMemberButtonExplanation = styled.p`
    font-size: 11px;
    padding-bottom: 10px;
    width: 80%;
    max-width: 500px;
`

export const MobileMemberButton = () => {
    return (
        <React.Fragment>
            <MobileMemberButtonExplanation>
                メンバーについて
            </MobileMemberButtonExplanation>
            <MobileMemberButtonOuter>
                <MobileMemberButtonInner>MEMBER</MobileMemberButtonInner>
                <MemberButtonIcon />
            </MobileMemberButtonOuter>
        </React.Fragment>
    )
}

const TopMobileHeaderButtonOuter = styled.button`
    /* position: relative; */
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    width: 50px;
    height: 50px;
    border: none;
    background: none;
`

const WhiteMobileHeaderButtonRectangle = styled.div`
    position: relative;
    display: inline-block;
    width: 25px;
    height: 2px;
    background: white;
    margin: 2px 0px 2px 0px;
`

const BlackMobileHeaderButtonRectangle = styled.div`
    position: relative;
    display: inline-block;
    width: 25px;
    height: 2px;
    background: black;
    margin: 2px 0px 2px 0px;
`

export const TopMobileHeaderMenuButton = ({ color, onClick }) => {
    return (
        <TopMobileHeaderButtonOuter onClick={() => onClick(true)}>
            {[0, 1, 2].map((item) => {
                if (color === 'white') {
                    return <WhiteMobileHeaderButtonRectangle key={item}></WhiteMobileHeaderButtonRectangle>
                } else {
                    return <BlackMobileHeaderButtonRectangle key={item}></BlackMobileHeaderButtonRectangle>
                }
            })}
        </TopMobileHeaderButtonOuter>
    )
}

const CloseHamburgerMenuButtonOuter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border: none;
    background: none;
`

const DliClose = styled.span`
    display: inline-block;
    vertical-align: middle;
    color: white;
    line-height: 1;
    width: 2em;
    height: 0.1em;
    background: currentColor;
    border-radius: 0.1em;
    position: relative;
    transform: rotate(45deg);
    &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: inherit;
    border-radius: inherit;
    transform: rotate(90deg);
  }
`

export const CloseHamburgerMenuButton = ({ onClickCloseMenu }) => {
    return(
        <CloseHamburgerMenuButtonOuter onClick={() => onClickCloseMenu(false)}>
            <DliClose></DliClose>
        </CloseHamburgerMenuButtonOuter>
    )
}


const MobileGoToTopButtonWrapper = styled.a`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    bottom: 60px;
    width: 120px;
    height: 140px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    color: white;
    text-decoration: none;
    transition: 0.4s all;
    &:hover {
        letter-spacing: 1.5px;
    }
`

const MobileGoToTopLine = styled.div`
    display: inline-block;
    width: 1px;
    height: 65px;
    margin-bottom: 7px;
    background: white;
`

export const MobileGoToTopButton = () => {
    return (
        <MobileGoToTopButtonWrapper href="#">
            <MobileGoToTopLine />
            GO TO TOP
        </MobileGoToTopButtonWrapper>
    )
}

const DoubleLabelButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
`

const DoubleLabelButtonOuter = styled.a`
    height: 60px;
    width: 85%;
    min-width: 280px;
    font-size: 14px;
    border: 1px solid #b2b2b2;
    background: none;
    position: relative;
    display: flex;
    align-items: center;
    fill: black;
    text-decoration: none;
    color: black;
    text-transform: uppercase;
    letter-spacing: 1px;
    &:before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        content: "";
        background-color: black;
        transform-origin: right top;
        transform: scale(0, 1);
        transition: transform 0.3s;
    }
    &:hover:before {
        transform-origin: left top;
        transform: scale(1, 1);
    }
    &:hover {
        color: #fff;
        fill: white;
        cursor: pointer;
    }
`

const DoubleLabelButtonInner = styled.div`
    display: flex;
    font-weight: 600;
    font-size: 16px;
    margin-left: 20px;
    align-items: center;
`

const DoubleLabelButtonExplanation = styled.p`
    padding-bottom: 10px;
    width: 85%;
    min-width: 280px;
    margin: 0 auto;
`

export const MobileDoubleLabelButton = ({ jpLabel, enLabel, href }) => {
    return (
        <DoubleLabelButtonWrapper>
            <DoubleLabelButtonExplanation>
                {jpLabel}
            </DoubleLabelButtonExplanation>
            <DoubleLabelButtonOuter href={href}>
                <DoubleLabelButtonInner>{enLabel}</DoubleLabelButtonInner>
                <MemberButtonIcon />
            </DoubleLabelButtonOuter>
        </DoubleLabelButtonWrapper>
    )
}

const MobileLogoButtonOuter = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 128px;
`

export const MobileLogoButton = props => {
    return (
        <MobileLogoButtonOuter href="/">
            {props.color === "white" && <TopHeaderLogoSVG />}
            {props.color === "black" && <HeaderLogoSVG />}
        </MobileLogoButtonOuter>
    )
}